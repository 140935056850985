import React, { forwardRef, useEffect, useState } from "react";
import Cookies from "js-cookie";
import Login from "./components/Login";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  VStack,
  Tag,
  Center,
} from "@chakra-ui/react";
import Target from "./components/Target";
import Header from "./components/Header";
import Cron from "react-js-cron";
import axios from "axios";
import { API_URL } from "./index";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DeleteIcon, SpinnerIcon } from "@chakra-ui/icons";
import CreatableSelect from "react-select/creatable";

axios.interceptors.request.use(function (config) {
  config!.headers!.Authorization = "Basic " + Cookies.get("token");
  return config;
});

var emptyTarget = {
  name: "",
  url: "",
  scheduleType: "cron",
  tags: [],
  schedule: "0 6 * * *",
  targetElement: "",
  script: "",
  offset: 0,
  full: false,
  timeout: 5,
  suspend: false,
  start: null,
  deadline: null,
};

function App() {
  const [login, setLogin] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [targets, setTargets] = useState<any[]>([]);
  const [targetNames, setTargetNames] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [uniqueTags, setUniqueTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [history, setHistory] = useState<any[]>([]);
  const [displayedHistory, setDisplayedHistory] = useState<any[]>([]);
  const [selectedTarget, setSelectedTarget] = useState("");
  const [newTarget, setNewTarget] = useState<any>({ ...emptyTarget });
  const DatePickerInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <Input w="100%" defaultValue={value} onClick={onClick} ref={ref}></Input>
  ));

  function updateField(field: string, value: any) {
    var temp = { ...newTarget };
    temp[field] = value;
    setNewTarget(temp);
  }

  function fetchTargets() {
    axios.get(`${API_URL}/api/targets`).then((response) => {
      if (response.data !== undefined) {
        response.data.map((t: any) => {
          console.log(t.start);
          t.start = new Date(t.start).getTime();
          console.log(t.start);
        });
        setTargets(response.data);
        var names = new Map<string, string>();
        response.data.forEach((target: any) =>
          names.set(target.id, target.name)
        );
        setTargetNames(names);
        var allTags: any[] = response.data.map((target: any) =>
          Array.isArray(target.tags) ? target.tags : []
        );
        setUniqueTags(Array.from(new Set(allTags.flat(1))));
      } else {
        console.error("failed to fetch targets");
      }
    });
    axios.get(`${API_URL}/api/history`).then((response) => {
      if (response.data !== undefined) {
        setHistory(response.data);
      } else {
        console.error("failed to fetch history");
      }
    });
  }

  useEffect(() => {
    if (selectedTarget.length > 0) {
      setDisplayedHistory([
        ...history.filter((record) => record.target === selectedTarget),
      ]);
    } else {
      setDisplayedHistory([...history]);
    }
  }, [history, selectedTarget]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Cookies.get("token") === undefined) {
      setLogin(true);
    } else {
      setLogin(false);
      fetchTargets();
    }
  }, [login]);
  return login ? (
    <Login
      onAuth={(password: string) => {
        Cookies.set("token", btoa("admin:" + password));
      }}
      onContinue={() => {
        setLogin(false);
      }}
    />
  ) : (
    <div>
      <Header
        onHistory={() => {
          setSelectedTarget("");
          setModalOpened(true);
        }}
        onNewTarget={() => {
          setNewTarget({ ...emptyTarget });
          setEditModalOpened(true);
        }}
      />
      <Modal
        isOpen={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt="40px">
            <Steps orientation="vertical" colorScheme="blue" activeStep={9999}>
              {displayedHistory.map((record, index) => {
                return (
                  <Step
                    key={`history-${index}`}
                    label={
                      selectedTarget.length > 0 ? (
                        <Box textAlign={"start"}>
                          <Text fontWeight={"bold"} color="black">
                            <Tooltip
                              label={moment(record.created).format(
                                "HH:mm DD.MM.YYYY"
                              )}
                              hasArrow
                              placement="right"
                            >
                              {moment(record.created).fromNow()}
                            </Tooltip>
                          </Text>
                          <Text color="black">{record.trigger}</Text>
                        </Box>
                      ) : (
                        <Box textAlign={"start"}>
                          <Text fontWeight={"bold"} color="black">
                            {targetNames.get(record.target)}
                          </Text>
                          <Text color="black">
                            <Tooltip
                              label={moment(record.created).format(
                                "HH:mm DD.MM.YYYY"
                              )}
                              hasArrow
                              placement="right"
                            >
                              {`${moment(record.created).fromNow()} (${
                                record.trigger
                              })`}
                            </Tooltip>
                          </Text>
                        </Box>
                      )
                    }
                  />
                );
              })}
            </Steps>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={editModalOpened}
        onClose={() => {
          setEditModalOpened(false);
        }}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <DeleteIcon
            display={newTarget.id ? "block" : "none"}
            boxSize={4}
            color="red.500"
            style={{
              position: "absolute",
              top: 15,
              right: 55,
              cursor: "pointer",
            }}
            onClick={() => {
              axios
                .delete(`${API_URL}/api/targets/${newTarget.id}`)
                .then(() => {
                  fetchTargets();
                  setEditModalOpened(false);
                });
            }}
          />
          <ModalCloseButton />

          <ModalBody mt="40px">
            <VStack spacing="15px">
              <Box w="100%">
                <Text mb="8px">Name</Text>
                <Input
                  value={newTarget.name}
                  onChange={(event) => updateField("name", event.target.value)}
                  size="md"
                />
              </Box>
              <Box w="100%">
                <Text mb="8px">URL</Text>
                <Input
                  value={newTarget.url}
                  onChange={(event) => updateField("url", event.target.value)}
                  size="md"
                />
              </Box>
              <Box w="100%">
                <Text mb="8px">Tags</Text>
                <CreatableSelect
                  isMulti
                  onChange={(options) => {
                    var tags = options.map((option: any) => option.label);
                    updateField("tags", tags);
                  }}
                  value={
                    newTarget.tags
                      ? newTarget.tags.map((tag: string) => {
                          return { label: tag, value: tag };
                        })
                      : []
                  }
                  options={[]}
                />
              </Box>
              <Box w="100%">
                <Text mb="8px">Target Element (XPath)</Text>
                <Input
                  value={newTarget.targetElement}
                  onChange={(event) =>
                    updateField("targetElement", event.target.value)
                  }
                  size="md"
                />
              </Box>
              <Box w="100%">
                <Text mb="8px">Offset</Text>
                <NumberInput
                  value={newTarget.offset}
                  onChange={(value) =>
                    updateField(
                      "offset",
                      parseInt(value.length > 0 ? value : "0")
                    )
                  }
                  size="md"
                >
                  <NumberInputField />
                </NumberInput>
              </Box>
              <Box w="100%">
                <Text mb="8px">Timeout</Text>
                <NumberInput
                  value={newTarget.timeout}
                  onChange={(value) =>
                    updateField(
                      "timeout",
                      parseInt(value) > 0 ? parseInt(value) : 5
                    )
                  }
                  size="md"
                >
                  <NumberInputField />
                </NumberInput>
              </Box>
              <Box w="100%">
                <Text mb="8px">Script</Text>
                <Textarea
                  whiteSpace={"nowrap"}
                  value={newTarget.script}
                  onChange={(event) =>
                    updateField("script", event.target.value)
                  }
                  size="md"
                />
              </Box>

              <Box w="100%">
                <Checkbox
                  isChecked={newTarget.full}
                  onChange={(event) => {
                    updateField("full", event.target.checked);
                  }}
                >
                  Full Page
                </Checkbox>
              </Box>
              <Box w="100%">
                <Text mb="8px">Schedule Type</Text>
                <Select
                  value={newTarget.scheduleType}
                  onChange={(event) => {
                    var temp = { ...newTarget };
                    if (event.target.value === "fixed") {
                      temp.schedule = Date.now().toString();
                    }
                    if (event.target.value === "cron") {
                      temp.schedule = "0 6 * * *";
                    }
                    if (event.target.value === "manual") {
                      temp.schedule = "";
                    }

                    temp.scheduleType = event.target.value;
                    setNewTarget(temp);
                  }}
                >
                  <option value="cron">Cron Job</option>
                  <option value="fixed">Fixed Date</option>
                  <option value="manual">Manual</option>
                </Select>
              </Box>
              <Box w="100%">
                <Text mb="8px">Schedule</Text>
                {newTarget.scheduleType === "cron" ? (
                  <Cron
                    value={newTarget.schedule}
                    setValue={(value: any) => {
                      updateField("schedule", value);
                    }}
                  />
                ) : newTarget.scheduleType === "fixed" ? (
                  <DatePicker
                    customInput={<DatePickerInput />}
                    dateFormat="HH:mm dd.MM.yyyy"
                    showPopperArrow={false}
                    showTimeInput={true}
                    onChange={(value) => {
                      if (value !== null) {
                        updateField("schedule", value.getTime().toString());
                      }
                    }}
                    selected={moment
                      .unix(parseInt(newTarget.schedule) / 1000)
                      .toDate()}
                  />
                ) : (
                  <Text color="black">Manual Trigger Selected</Text>
                )}
              </Box>
              <Box w="100%">
                <Checkbox
                  isChecked={newTarget.suspend}
                  onChange={(event) => {
                    updateField("suspend", event.target.checked);
                  }}
                >
                  Suspend
                </Checkbox>
              </Box>
              {newTarget.suspend ? (
                <HStack w="100%">
                  <Box w="100%">
                    <Text mb="8px">Start Date</Text>
                    <DatePicker
                      isClearable
                      customInput={<DatePickerInput />}
                      dateFormat="HH:mm dd.MM.yyyy"
                      showPopperArrow={false}
                      showTimeInput={true}
                      onChange={(value) => {
                        updateField(
                          "start",
                          value !== null ? value.getTime() : null
                        );
                      }}
                      selected={
                        newTarget.start !== null
                          ? new Date(newTarget.start)
                          : null
                      }
                    />
                  </Box>
                  <Box w="100%">
                    <Text mb="8px">Deadline</Text>
                    <DatePicker
                      isClearable
                      customInput={<DatePickerInput />}
                      dateFormat="HH:mm dd.MM.yyyy"
                      showPopperArrow={false}
                      showTimeInput={true}
                      onChange={(value) => {
                        updateField(
                          "deadline",
                          value !== null ? value.getTime() : null
                        );
                      }}
                      selected={
                        newTarget.deadline !== null
                          ? new Date(newTarget.deadline)
                          : null
                      }
                    />
                  </Box>
                </HStack>
              ) : null}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                newTarget.start = new Date(newTarget.start);
                newTarget.deadline = new Date(newTarget.deadline);
                if (newTarget.id) {
                  axios
                    .put(`${API_URL}/api/targets/${newTarget.id}`, newTarget)
                    .then(() => {
                      fetchTargets();
                      setEditModalOpened(false);
                    });
                } else {
                  axios.post(`${API_URL}/api/targets`, newTarget).then(() => {
                    fetchTargets();
                    setEditModalOpened(false);
                  });
                }
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setEditModalOpened(false);
              }}
              variant="ghost"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Center w="100vw" mt="3em">
        <HStack align="center">
          {uniqueTags.map((tag) => (
            <Tag
              cursor="pointer"
              size="lg"
              variant="subtle"
              colorScheme={selectedTags.includes(tag) ? "teal" : "cyan"}
              onClick={() => {
                if (!selectedTags.includes(tag)) {
                  setSelectedTags((tags) => [...tags, tag]);
                } else {
                  var temp = [...selectedTags];
                  var index = temp.indexOf(tag);
                  if (index !== -1) {
                    temp.splice(index, 1);
                    setSelectedTags(temp);
                  }
                }
              }}
            >
              {tag}
            </Tag>
          ))}
        </HStack>
      </Center>
      <Box
        p={{ base: "4em", md: "6em" }}
        pt={{ base: "1em", md: "2em" }}
        bg="#f8f9fb"
        className="container"
      >
        <SimpleGrid minChildWidth="280px" spacing="40px" spacingY="15px">
          {targets.map((target, index) => {
            if (
              selectedTags.length < 1 ||
              (target.tags || []).filter((x: string) =>
                (selectedTags || []).includes(x)
              ).length > 0
            ) {
              return (
                <Target
                  history={history.filter(
                    (record) => record.target === target.id
                  )}
                  data={target}
                  onClick={() => {
                    setNewTarget({ ...target });
                    setEditModalOpened(true);
                  }}
                  onRightClick={(event: any) => {
                    setModalOpened(true);
                    setSelectedTarget(target.id);
                  }}
                  key={"target-" + index}
                />
              );
            } else {
              return null;
            }
          })}
        </SimpleGrid>
      </Box>
    </div>
  );
}

export default App;
