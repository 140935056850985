import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  HStack,
  IconButton,
  Text,
  PinInput,
  PinInputField,
  VStack,
  Divider,
} from "@chakra-ui/react";
import axios from "axios";
import { useRef, useState } from "react";
import { API_URL } from "../index";
import { FaHeart } from "react-icons/fa";

type Props = {
  onAuth: any;
  onContinue: any;
};

export default function Login(props: Props) {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const lastPinRef = useRef<HTMLInputElement>(null);
  return (
    <div className="login">
      <VStack mb={{ base: "0", md: "200px" }}>
        <HStack>
          <Text
            fontWeight={"semibold"}
            mb={"10px"}
            fontSize={"40px"}
            color="black"
          >
            Welcome back!{" "}
          </Text>
        </HStack>

        <HStack>
          <PinInput
            onComplete={(value) => {
              setPassword(value);

              if (!loading) {
                setLoading(true);
                if (lastPinRef.current) {
                  lastPinRef.current.blur();
                }
                axios
                  .get(`${API_URL}/api/ping`, {
                    auth: {
                      username: "admin",
                      password: value,
                    },
                  })
                  .then(() => {
                    setAuthenticated(true);
                    setLoading(false);
                    props.onAuth(value);
                  })
                  .catch(() => {
                    setInvalid(true);
                    setPassword("");
                    setLoading(false);
                  });
              }
            }}
            onChange={(value) => {
              setPassword(value);
            }}
            value={password}
            isInvalid={invalid}
            mask
            size="lg"
            otp={true}
          >
            <PinInputField
              onFocus={() => {
                setInvalid(false);
              }}
            />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField ref={lastPinRef} />
          </PinInput>
          <IconButton
            isLoading={loading}
            isDisabled={!authenticated}
            onClick={props.onContinue}
            size="lg"
            variant="outline"
            aria-label="Login"
            icon={<ArrowForwardIcon />}
          />
        </HStack>
      </VStack>
    </div>
  );
}
