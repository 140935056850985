import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Image,
  AspectRatio,
  HStack,
  Tag,
  Tooltip,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import moment from "moment";
import parser from "cron-parser";
import { Browser } from "react-kawaii";

function randomDate(start: Date, end: Date) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}
type Props = {
  data: any;
  history: any[];
  onClick: any;
  onRightClick: any;
};

export default function Target(props: Props) {
  const [nextRun, setNextRun] = useState("manual");

  useEffect(() => {
    if (
      props.data.scheduleType === "cron" &&
      typeof props.data.schedule === "string"
    ) {
      setNextRun(
        moment(
          parser.parseExpression(props.data.schedule).next().toDate()
        ).fromNow()
      );
    }
    if (props.data.scheduleType === "fixed") {
      setNextRun(
        moment(moment.unix(parseInt(props.data.schedule) / 1000)).fromNow()
      );
    }
  }, [props.data]);

  return (
    <Center py={12}>
      <Box
        onContextMenu={(event) => {
          props.onRightClick(event);
          event.preventDefault();
        }}
        onClick={props.onClick}
        cursor="pointer"
        role={"group"}
        p={6}
        pb={4}
        px={4}
        maxW={"330px"}
        w={"full"}
        bg={"white"}
        boxShadow={"2xl"}
        rounded={"lg"}
        pos={"relative"}
        zIndex={1}
        transition={"all .2s ease-in-out"}
        _hover={{
          transform: "scale(1.01)",
        }}
      >
        <Box
          rounded={"lg"}
          mt={-12}
          pos={"relative"}
          _after={{
            transition: "all .3s ease",
            content: '""',
            w: "full",
            h: "full",
            pos: "absolute",
            top: 5,
            left: 0,
            backgroundImage: `data:image/png;base64,${
              props.data.screenshot && props.data.screenshot !== null
                ? props.data.screenshot
                : "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
            }`,
            backgroundSize: "cover",
            filter: "blur(10px)",
            zIndex: -1,
          }}
        >
          {!props.data.screenshot || props.data.screenshot === null ? (
            <Center w="100%" mb="10px">
              <Browser mood="happy" color="#88C0D0" />
            </Center>
          ) : (
            <AspectRatio maxW="100%" ratio={4 / 3}>
              <Image
                rounded={"lg"}
                objectFit="cover"
                src={`data:image/png;base64,${props.data.screenshot}`}
              />
            </AspectRatio>
          )}
        </Box>
        <Stack pt={10} align={"center"}>
          <Text color={"gray.500"} fontSize={"sm"} textTransform={"uppercase"}>
            <span className={`floating-dot success`}></span> {nextRun}
          </Text>

          <Tooltip label={props.data.name}>
            <Heading
              textAlign="center"
              fontSize={"2xl"}
              fontFamily={"body"}
              fontWeight={500}
              noOfLines={1}
            >
              {props.data.name}
            </Heading>
          </Tooltip>

          <Center w="100%" h="35px" overflow={"scroll"}>
            <HStack pt="10px">
              {props.data.tags
                ? props.data.tags.map((tag: string) => (
                    <Tag size="sm">{tag}</Tag>
                  ))
                : null}
            </HStack>
          </Center>
          <Stack direction={"row"} align={"center"}>
            <Text color={"gray.600"}>{props.history.length} snapshots</Text>
            {/* <SiMicrosoftsharepoint style={{ color: "#728297" }} /> */}
          </Stack>
        </Stack>
      </Box>
    </Center>
  );
}
